import './BlogPage.css'
import React from 'react';
import request from '../request';
import i18n from '../i18n';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faArrowRight, faCalendarAlt, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

class BlogPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      articles: [],
      page: 1,
    };
  }

  componentDidMount() {
    request.get(`api/articles/1`).then(response => {
      if(!request.response_valid(response)){
        return;
      }
      const perPage = Math.ceil(response.data.total / 6);
      this.setState({
        ...this.state,
        articles: response.data.articles,
        total: response.data.total,
        page: 1,
        pagesCount: perPage
      })
    })
  }

  changePage(page) {
    request.get(`api/articles/${page}`).then(response => {
      if(!request.response_valid(response)){
        return;
      }
      const perPage = Math.ceil(response.data.total / 6);
      this.setState({
        ...this.state,
        articles: response.data.articles,
        total: response.data.total,
        page: page,
        pagesCount: perPage
      })
    })
  }

  render() {
    return (
      <div className="blog-page">
        <div className='container'>
          {
            this.state.articles.map((article, i) => {
              return (
                <Link
                  key={i}
                  className='blog-element'
                  to={`article/${article.id}`}
                >
                  <div class='blog-item'>
                    <div class='blog-img'>
                      {
                        article.thumbnail && article.thumbnail !== '' &&
                        <img src={article.thumbnail}/>
                      }
                    </div>
                    <div class='blog-details'>
                    <span><FontAwesomeIcon icon={faCalendarAlt}/> {article.date_created}</span>
                      <h4>{i18n.get_title(article)}</h4>
                      <p id="terms-wrapper" dangerouslySetInnerHTML={{__html: article.teaser}}></p>
                      <div class='readmore'>
                          <span>{i18n.t('more')} <FontAwesomeIcon icon={faLongArrowAltRight}/></span>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })
          }
        </div>
        <div className='container'>
          <div className={`pagination ${!this.state.page ? 'hidden' : ''}`}>
              <div onClick={() => this.changePage(Math.max(1, this.state.page - 1))}><FontAwesomeIcon icon={faAngleLeft} /></div>
              {
                this.state.page > 3
                  ? <div onClick={() => this.changePage(1)}>1</div>
                  : ''
              }
              {
                this.state.page > 2
                  ? <div onClick={() => this.changePage(this.state.page - 2)}>{this.state.page - 2}</div>
                  : ''
              }
              {
                this.state.page > 1
                  ? <div onClick={() => this.changePage(this.state.page - 1)}>{this.state.page - 1}</div>
                  : ''
              }
              {
                this.state.pagesCount > 0
                  ? <div className="active">{this.state.page}</div>
                  : ''
              }
              {
                parseInt(this.state.page) + 1 <= this.state.pagesCount
                  ? <div onClick={() => this.changePage(parseInt(this.state.page) + 1)}>{parseInt(this.state.page) + 1}</div>
                  : ''
              }
              {
                parseInt(this.state.page) + 2 <= this.state.pagesCount
                  ? <div onClick={() => this.changePage(parseInt(this.state.page) + 2)}>{parseInt(this.state.page) + 2}</div>
                  : ''
              }
              {
                parseInt(this.state.page) + 3 <= this.state.pagesCount
                  ? <div onClick={() => this.changePage(parseInt(this.state.page) + 3)}>{parseInt(this.state.page) + 3}</div>
                  : ''
              }
              
              <div onClick={() => this.changePage(Math.min(this.state.pagesCount, parseInt(this.state.page) + 1))}><FontAwesomeIcon icon={faAngleRight} /></div>
            </div>
          </div>
      </div>
    );
  }
}

export default BlogPage;