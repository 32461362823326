const config = {
  imagesBaseURL: 'https://manager.cyprus.presentteam.eu/images/',
  imagesDevBaseURL: 'https://manager.cyprus.presentteam.eu/images/',
  //imagesBaseURL: 'http://localhost/iarts/eshop/admin/images/',
  eshop_title: 'Cotton plus',
  enviroment: 'development',
  // enviroment: 'production',
  picking: false,
  site_url: 'https://cyprus.presentteam.eu/'
};

export default config;