import './Terms.css';
import React from 'react';
import i18n from '../i18n';
import request from '../request';
import history from '../history';
import { useParams } from "react-router-dom";


function withParams(Component) {
    return props => <Component {...props} params={useParams()} />;
}

class Terms extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            terms: [],
            id: this.props.params.id
        };

        this.getTerms = this.getTerms.bind(this);
    }
    
    onLanguageChange = () => {
        this.forceUpdate();
    }

    componentDidMount() {
        document.title = i18n.t("terms_page_title");
        this.onHistoryChangeUnlisten = history.listen(() => {
            this.setState({
                ...this.state,
                id: this.props.params.id
            }, () => {
                this.getTerms()
            });
        });
        this.getTerms()
        this.unlistenOnLanguageChange = i18n.onLanguageChange(this.onLanguageChange);
    }

    componentWillUnmount() {
        this.unlistenOnLanguageChange();
    }
    
    getTerms() {
        request.get(`api/terms-of-use/${this.props.params.id}`).then((response) => {
            this.setState({
                ...this.state,
                terms: response.data
            });
            
        });
    }
    
    render() {
        return (
            <div className='column container'>
                {/* <h3 className='title top-title'>ΟΡΟΙ ΚΑΙ ΠΡΟΥΠΟΘΕΣΕΙΣ ΧΡΗΣΗΣ</h3> */}
                <div id="terms-wrapper" style={{minHeight: "60vh"}}>
                    {this.state.terms.map( (term) => {
                        return(
                            <div className='content'>
                                <h3 className='title text-center'>{i18n.toggle(term.title, term.title_en)}</h3>
                                <div className='term-content'>
                                    <p id="terms-wrapper" dangerouslySetInnerHTML={{__html: i18n.toggle(term.content, term.content_en)}}>
                                        
                                    </p>
                                </div>
                            </div>
                        )
                    })}
                </div>
                
            </div>
        );
    }
}
export default withParams(Terms);