import './ProductAvailability.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'

class ProductAvailability extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="ProductAvailability">
        {/* <div className="mt-1" style={{fontSize: '14px', color: '#FFF', backgroundColor: `#${this.props.data.color}`, borderRadius: '5px', padding: '8px', maxWidth: '168px', display: 'inline-block'}}>
          <FontAwesomeIcon icon={faPaperPlane} />
          &nbsp;&nbsp;&nbsp;
          {this.props.data.name}
        </div> */}
      </div>
    );
  }
}

export default ProductAvailability;