import request from './request';
import basket from './basket';

class DiscountBasket {
  constructor() {
    this.validateBasket()
  }
  basket_init = false;
  products = [];
  onAddProductFns = [];
  onRemoveProductFns = [];
  onChangeFns = [];
  onQtyChangeFns = [];

  validateBasketStorage() {
    let basket = localStorage.getItem('basket');
    if (basket) {
      basket = JSON.parse(basket);
    } else {
      basket = [];
    }

    if(basket.length == 0){
      localStorage.setItem('discountBasket', '[]')
      return
    }

    let discount_basket = localStorage.getItem('discountBasket');
    if (discount_basket) {
      discount_basket = JSON.parse(discount_basket);
    } else {
      discount_basket = [];
    }

    if(discount_basket.length == 0){
      return
    }

    request.post(`api/product-discount-validate`, {basket: basket, discount_basket: discount_basket}, false).then((response) => {
      //console.log(response.data)
      localStorage.setItem('discountBasket', JSON.stringify(response.data))
    })
  }

  validateBasket() {
    let basket = localStorage.getItem('basket');
    if (basket) {
      try {
        basket = JSON.parse(basket);
      } catch(e) {
        basket = [];
      }
    } else {
      basket = [];
    }

    if(basket.length == 0){
      localStorage.setItem('discountBasket', '[]')
      return
    }

    let discount_basket = localStorage.getItem('discountBasket');
    if (discount_basket) {
      try {
        discount_basket = JSON.parse(discount_basket);
      } catch(e) {
        discount_basket = [];
        localStorage.setItem('discountBasket', '[]');
      }
    } else {
      discount_basket = [];
    }

    if(discount_basket.length == 0){
      return
    }

    request.post(`api/product-discount-validate`, {basket: basket, discount_basket: discount_basket}, false).then((response) => {
      //console.log(response.data)
      localStorage.setItem('discountBasket', JSON.stringify(response.data))
      this.initializeBasket()
    })
  }
  initializeBasket(){
    
    request.get_user_status().then( (r) => {
      if(r){//test
        
        this.basket_init = true;
        let localstorageData = localStorage.getItem('discountBasket');
        let localstorageProducts = [];
        if (localstorageData) {
          try {
            localstorageProducts = JSON.parse(localstorageData);
          } catch(e) {
            localstorageProducts = [];
            localStorage.setItem('discountBasket', '[]');
            return;
          }
        } else {
          localstorageProducts = [];
          return;
        }
        let promises = [];
        this.products = [];
        
        localstorageProducts.forEach( (product) => {
          promises.push(request.post(`api/product-discount/${product.discount.id}`, {basket: basket}, false).then((response) => {
            if(!response.data.id) return;
            if(this.products.filter(p => p.id == product.id && p.variant_id == product.variant_id).length > 0) return;
            let variant_id = 0;
            if(product.selectedVariant){
              variant_id = product.selectedVariant.id;
            }
            let selectedVariant = false;
            let title = '#' + response.data.code + ' ' + response.data.title;
            let title_en = '#' + response.data.code + ' ' + response.data.title_en;
            if(variant_id > 0){
              if(response.data.variants){
                selectedVariant = response.data.variants[response.data.variants.findIndex( (v) => v.id === variant_id)];
                title += " (";
                title_en += " (";
                selectedVariant.attributes.forEach( (a) => {
                  title += `${a.attribute_category}: ${a.value} `;
                  title_en += `${a.attribute_category_en}: ${a.value} `;
                });
                title += " )";
                title_en += " )";
              }
            }
            //if(not_variant_missmatch){p.discount == product.discount && p.discount_from ===  product.discount_from
              this.products.push({
                ...response.data,
                name: title,
                name_en: title_en,
                thumbnail: this.getImage(response.data, selectedVariant),
                quantity: product.quantity,
                selectedVariant: selectedVariant,
                variant_id: variant_id,
                discount: product.discount,
              });
            //}
            
          }));
        });
        Promise.all(promises).then(() => {
          this.onChangeFns.forEach((fn) => fn());
        });
      }
    })
  }
  removeDiscountProducts(discount) {
    const prods = JSON.parse(JSON.stringify(this.products));
    //const filtered_products = prods.filter((p) => p.discount.id !== discount.id);
    const prods_to_delete = prods.filter((p) => p.discount.id === discount.id);

    prods_to_delete.forEach((p) => {
      this.removeProduct(p, p.variant_id, p.discount)
    })


  }

  filterDependencies(product, qty) {
    console.log("product qty is", qty)
    const prods = JSON.parse(JSON.stringify(this.products));
    const filtered_prods = prods.filter((p) => p.discount.product_id === product.id);

    filtered_prods.forEach((p) => {
      
      if(p.discount.total_qty > qty){
        this.removeDiscountProducts(p.discount)

        if(p.discount.qty <= qty){
          this.onQtyChangeFns.forEach((fn) => {
            fn();
          });
        }
      }

      
      /**
        this.onChangeFns.forEach((fn) => {
          fn();
        });
       */
    })
  }

  addProduct(product, quantity, discount) {
    
    let variant_id = 0;
    if (product.selectedVariant) {
      variant_id = product.selectedVariant.id;
    }
    const productIndex = this.products.findIndex((p) => p.id === product.id && p.variant_id == variant_id && p.discount.id == discount.id);
    let title = '#' + product.code + ' ' + product.name;
    let title_en = '#' + product.code + ' ' + product.name_en;
    if (productIndex > -1) {
      this.products[productIndex].quantity = quantity;
      this.products[productIndex].selectedVariant = product.selectedVariant ? product.selectedVariant : false;
      this.products[productIndex].thumbnail = this.getImage(product, product.selectedVariant);
      if(product.selectedVariant){
        title += " (";
        title_en += " (";
        product.selectedVariant.attributes.forEach( (a) => {
          title += `${a.attribute_category}: ${a.value} `;
          title_en += `${a.attribute_category_en}: ${a.value} `;
        });
        title += " )";
        title_en += " )";
      }
      this.products[productIndex].name = title;
      this.products[productIndex].name_en = title_en;
    } else {
      
      if(product.selectedVariant){
        title += " (";
        title_en += " (";
        product.selectedVariant.attributes.forEach( (a) => {
          title += `${a.attribute_category}: ${a.value} `;
          title_en += `${a.attribute_category_en}: ${a.value} `;
        });
        title += " )";
        title_en += " )";
      }
      this.products.push({
        ...product,
        name: title,
        name_en: title_en,
        variant_id: variant_id,
        selectedVariant: product.selectedVariant ? product.selectedVariant : false,
        thumbnail: this.getImage(product, product.selectedVariant), 
        quantity: quantity,
        discount: discount,
      });
    }
    
    let localstorageProducts = localStorage.getItem('discountBasket');
    if (localstorageProducts) {
      try{
        localstorageProducts = JSON.parse(localstorageProducts);
      }catch(e) {
        localstorageProducts = [];
      }
    } else {
      localstorageProducts = [];
    }
    let localstorageIndex = localstorageProducts.findIndex((p) => p.id === product.id && p.variant_id == variant_id && p.discount.id == discount.id);
    if (localstorageIndex > -1) {
      localstorageProducts[localstorageIndex].quantity = quantity;
      localstorageProducts[localstorageIndex].selectedVariant = product.selectedVariant;
    }else{
      localstorageProducts.push({
        id: product.id,
        variant_id: variant_id,
        selectedVariant: product.selectedVariant ? product.selectedVariant : false,
        quantity: quantity,
        discount: discount
      });
    }
    
    localStorage.setItem('discountBasket', JSON.stringify(localstorageProducts));

    this.onAddProductFns.forEach((fn) => {
      fn(product);
    });
    this.onChangeFns.forEach((fn) => {
      fn();
    });

  }

  removeProduct(product, variant_id, discount) {
    
    let localstorageProducts = localStorage.getItem('discountBasket');
    if (localstorageProducts) {
      localstorageProducts = JSON.parse(localstorageProducts);
    } else {
      return;
      //localstorageProducts = {};
    }
    const localstorageIndex = localstorageProducts.findIndex((p) => p.id === product.id && p.variant_id === variant_id && p.discount.id == discount.id);
    if(localstorageIndex == -1) return;
    
    this.products.splice(this.products.findIndex((p) => p.id === product.id && p.variant_id === variant_id && p.discount.id == discount.id), 1);
    

    localstorageProducts.splice(localstorageIndex, 1);
    localStorage.setItem('discountBasket', JSON.stringify(localstorageProducts));

    this.onRemoveProductFns.forEach((fn) => {
      fn(product, variant_id);
    });
    this.onChangeFns.forEach((fn) => {
      fn();
    });
  }

  getImage(product, variant){
    let url = product.thumbnail;
    if(variant && product.colors){
      let sel = variant.attributes.filter( (a) => {return a.attribute_category_id == 1});
      if(sel.length > 0){
        let color = product.colors.filter( (v) => {
          return v.value == sel[0].value_id
        
        } )[0];
        if(typeof color !== "undefined"){
          url = color.url;
        }
      }
    }
    return url;
  }

  onAddProduct(fn) {
    this.onAddProductFns.push(fn);
  }

  onRemoveProduct(fn) {
    this.onRemoveProductsFns.push(fn);
  }

  onChange(fn) {
    this.onChangeFns.push(fn);

    return () => {
      this.onChangeFns.splice(this.onChangeFns.indexOf(fn), 1);
    };
  }

  onQtyChange(fn) {
    this.onQtyChangeFns.push(fn);

    return () => {
      this.onQtyChangeFns.splice(this.onQtyChangeFns.indexOf(fn), 1);
    };
  }

  clear() {
    this.products = [];
    localStorage.setItem('discountBasket', '[]');
    this.onChangeFns.forEach((fn) => {
      fn();
    });
  }

  clear_object() {
    this.products = [];
    
    this.onChangeFns.forEach((fn) => {
      fn();
    });
  }
}

const discountBasket = new DiscountBasket();
window.discountBasket = discountBasket;

export default discountBasket;