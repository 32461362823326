import './HomeCategories.css';
import React from 'react';
import {
  Link,
} from "react-router-dom";
import i18n from '../i18n';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import config from '../config';



class HomeCategories extends React.Component {
  constructor(props) {
    super(props);

    console.log(props.petros)
    this.state = {
    };
  }

  componentDidMount() {
    
  }

  

  componentDidUpdate() {
  }

  componentWillUnmount() {
  }

  render() {
    return (
        <div>
            <div className="title-wrapper">
                <h3 className="title">{i18n.t("categories")}</h3>
            </div>
            <div className="category-row">
                <div className="cat-wrapper"> 
                    <Slider {...{
                        dots: false,
                        infinite: true,
                        speed: 500,
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        responsive: [
                            {
                              breakpoint: 1200,
                              settings: {
                                arrows: false,
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                infinite: true,
                                dots: false
                              }
                            },
                            {
                              breakpoint: 600,
                              settings: {
                                arrows: false,
                                slidesToShow: 2,
                                slidesToScroll: 2,
                                initialSlide: 2
                              }
                            },
                            {
                              breakpoint: 480,
                              settings: {
                                arrows: false,
                                slidesToShow: 1,
                                slidesToScroll: 1
                              }
                            }
                          ]
                    }}>
                    {
                        this.props.categories &&
                        this.props.categories.map((c, i) => {
                            let imageURL
                            if (c.background_image) {
                                imageURL = `${config.imagesBaseURL}categories/${c.background_image}`;
                              } else if (c.image) {
                                imageURL = `${config.imagesBaseURL}categories/${c.image}`;
                              } else if (c.fallback_image){
                                imageURL = `${c.fallback_image}`;
                              } else {
                                imageURL = `/no-image-image.png`;
                              }
                            return <div key={i} className="elementor-widget-container">
                                        <div className="areas-item" style={{backgroundImage: `url(${imageURL})`}}>
                                            <div className="grid-item-link hover-effect-flat">
                                                <div className="areas-item-text">
                                                    <Link to={`/categories/${c.id}`}>
                                                    <div className="grid-item-title">{i18n.get_name(c)}</div>
                                                    </Link>
                
                                                </div>
                                            </div>
                                        </div>                    
                                    </div>
                        })
                        
                    }
                    </Slider>  
                </div>
            </div>
        </div>
    );
  }
}

export default HomeCategories;