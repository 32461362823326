const storage = {
  categories: [],
  company: {},
  me: {},
  discounts: [],
  onChangeFns: [],

  onChange(fn) {
    this.onChangeFns.push(fn);
    return this.onChangeFns.length - 1 
  },

  clearOnChange(key) {
    this.onChangeFns[key] = () => {}
  },
  
  set(propertyKey, data) {
    this[propertyKey] = data; 
    this.onChangeFns.forEach((fn) => {
      fn(propertyKey);
    });
  },
};
window.storage = storage;

export default storage;