import './CategoryItem.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import config from '../config';

class CategoryItem extends React.Component {
  render() {
    let imageURL;
    if (this.props.data.image) {
      imageURL = `${config.imagesBaseURL}categories/${this.props.data.image}`;
    } else if (this.props.data.fallback_image){
      imageURL = `${this.props.data.fallback_image}`;
    } else {
      imageURL = `/no-image-image.png`;
    }
    
    return this.props.data.productCount > 0 ? (
      <div className="category-item">
        <div
          className="image"
          style={{background: `url('${imageURL}')  center center / contain no-repeat`}}
        >
          <Link
            to={`/categories/${this.props.data.id}`}
            aria-label={i18n.get_name(this.props.data)}
          ></Link>
        </div>
        <div className="info">
          <Link to={`/categories/${this.props.data.id}`} className="name">{i18n.get_name(this.props.data)}</Link>
          <span className="product-count">{this.props.data.productCount}&nbsp;{i18n.t('products')}</span>
        </div>
      </div>
    ) : ''
  }
}

export default CategoryItem;