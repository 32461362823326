import './ProductsHomePage.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import CategoriesItem from '../categories-item/CategoriesItem';
import request from '../request';
import ProductItem from '../product-item/ProductItem';
import ProductListItem from '../product-list-item/ProductListItem';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoxOpen, faAngleLeft, faAngleRight, faFilter, faSort, } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { } from '@fortawesome/free-brands-svg-icons'
import history from '../history';
import SkeletonProductItem from '../product-skeleton-item/SkeletonProductItem';
import storage from '../storage';
import InputRange from '../range-input/RangeInput';
import ReactTooltip from "react-tooltip";

class ProductsHomePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      products: [],
      fetchingProducts: false,
      fetchIsDirty: false,
      categories: [],
      subcategories: [],
      currentCategory: [],
      categoriesExpanded: [],
      attributes: [],
      category: '589',
      page: 1,
      pagesCount: 0,
      total: 0,
      displayedProducts: [],
      showFilters: false,
      sortMenuOpen: false,
      sort: {
        by: 'date',
        type: 'desc',
      },
      viewTypeMenuOpen: false,
      viewType: 'grid',
      filterAttributesValues: [],
      filters: {
        
      },
    };


    this.elementRef = React.createRef();

    this.sort = this.sort.bind(this);
    this.getProducts = this.getProducts.bind(this);
    this.chooseFilterAttributesValue = this.chooseFilterAttributesValue.bind(this);
  }

  componentDidMount() {
    this.getProducts();
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });


  }

  componentDidUpdate(prevProps, prevState) {
    
    if (prevState.fetchingProducts !== this.state.fetchingProducts) {
      this.setState({
        ...this.state,
        fetchIsDirty: true,
      });
    }
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }

  getProducts(isLite = false) {
    
    this.setState({
      ...this.state,
      fetchingProducts: true,
    });

    let filterAttributesValuesParam = '';
    if (this.state.filterAttributesValues.length > 0) {
      filterAttributesValuesParam = `&attributes=${this.state.filterAttributesValues.join(',')}`;
    }

    let getProductsURL;
    getProductsURL = `api/products/category/${this.state.category}?page=${this.state.page}${filterAttributesValuesParam}&order_by=${this.state.sort.by}&order_type=${this.state.sort.type}${isLite ? '&lite=1' : ''}`;
    
    /////////
    request.get(`api/categories/${this.state.category}`).then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      this.state.currentCategory = response.data;
    });
    ///////
    
    request.get(getProductsURL).then((response) => {
      if(!request.response_valid(response)){
        return;
      }
      let attributes = this.state.attributes;
      if (response.data.data && response.data.data.attributes && (attributes.length === 0 || isLite === false)) {
        attributes = response.data.data.attributes;
        if (!('attributes' in response.data.data)) {
          attributes = [];
        }

      }
      
      if (response.data.data) {

        

        this.setState({
          ...this.state,
          total: response.data.data.total,
          pagesCount: Math.ceil(response.data.data.total / 12),
          products: response.data.data.products.map((product) => {
            return {
              id: product.id,
              name: product.title,
              name_en: product.title_en,
              code: product.code,
              description: product.description,
              description_en: product.description_en,
              image: product.images[0] || product.thumbnail,
              rating: product.rating,
              price: product.price,
              availability: product.availability,
              colors: product.colors,
            };
          }),
          subcategories: 'categories' in response.data.data ? response.data.data.categories.map((subcategory) => {
            return {
              id: subcategory.id,
              name: subcategory.name,
              name_en: subcategory.name_en,
              image: subcategory.image,
              fallback_image: subcategory.fallback_image,
              productCount: subcategory.productsCount
            };
          }) : [],
          fetchingProducts: false,
          attributes: attributes,
          filters: {
            ...this.state.filters,
          },
        });
      }
    }).catch( (e) => {
      console.log("aborted?", e)
    })
  }

  sort(info) {
    const property = info.split('-')[0];
    const order = info.split('-')[1];

    this.setState({
      ...this.state,
      sortMenuOpen: false,
      sort: {
        by: property,
        type: order,
      },
    });

    setTimeout(() => {
      this.getProducts(true);
    });
  }

  chooseFilterAttributesValue(value) {
    const fav = this.state.filterAttributesValues.slice(0);
    const valueIndex = fav.indexOf(value);
    if (valueIndex > -1) {
      fav.splice(valueIndex, 1);
    } else {
      fav.push(value);
    }

    this.setState({
      ...this.state,
      filterAttributesValues: fav,
    });

    setTimeout(() => {
      this.getProducts(true);
    });
  }


  

  changePage(page) {
    this.setState({
      ...this.state,
      page: page,
    });

    document
    .getElementById('products-list')
    .scrollIntoView({ behavior: 'smooth' });

    setTimeout(() => {
      this.getProducts(true);
    });
  }

  render() {
    return (
      <div className='products-container'>
        <div
          className="products-home-page"
          ref={this.elementRef}
        >
          <div className='filters-home-wrapper'>
            <div className="options">
              <div className="d-flex">
                <div className='filter--toggle'>
                  <button
                    type="button"
                    className={`btn ${this.state.showFilters === false ? 'btn-outline' : ''} ml-1`}
                    onClick={() => this.setState({ ...this.state, showFilters: !this.state.showFilters, })}
                  >
                    <FontAwesomeIcon icon={faFilter} />
                    &nbsp;
                    {i18n.t('filters')}
                  </button>
                </div>
                
                
              </div>
            </div>
            <div className={`filters filters--${this.state.viewType} ${this.state.showFilters ? 'open' : ''} p-1`} >
              <div className="attributes">
                {
                  this.state.attributes.map((attribute, i) => {
                    return (
                      <div
                        className="attribute"
                        key={i}
                      >
                        <div className="name">{i18n.get_name(attribute)}</div>
                        <div className="choices">
                          {
                            attribute.id == 1 ?
                            attribute.values.map((color, i) => {
                              if(color.custom_group_code != null && color.custom_group_code != ''){
                                let colors = [];
                                let grad;
                                if(color.custom_group_code.indexOf("|") > -1){
                                  colors = color.custom_group_code.split("|");
                                  grad = 'linear-gradient(to right top';
                                  colors.forEach( (c) => {
                                    grad += ', #' + c;
                                  } )
                                  grad += ')';
                                }else{
                                  grad = "#" + color.custom_group_code;
                                }
                                
                                //linear-gradient(to right top, #ff0000, #f36300, #de9200, #c2b700, #a0d600, #74dd54, #46e085, #00e1ad, #00c6d9, #00a5ff, #0078ff, #0000ff)
                                  return (
                                    <div
                                      key={i}
                                      className={`color-choice choice ${this.state.filterAttributesValues.indexOf(color.id) > -1 ? 'selected' : ''}`}
                                      onClick={() => this.chooseFilterAttributesValue(color.id)}
                                    >
                                      <div 
                                        className="color-value" 
                                        style={{background: grad}}
                                        data-tip data-for={`registerTip-filter-${color.id}`}
                                      >
                                        <ReactTooltip id={`registerTip-filter-${color.id}`} place="top" effect="solid">
                                          {color.custom_group_text}
                                        </ReactTooltip>
                                      </div>
                                    </div>
                                    
                                  )
                                //}
                              }
                            }) :
                            attribute.values.map((value, i) => {
                              return (
                                <div
                                  key={i}
                                  className={`size-choice choice ${this.state.filterAttributesValues.indexOf(value.id) > -1 ? 'selected' : ''}`}
                                  onClick={() => this.chooseFilterAttributesValue(value.id)}
                                >
                                  {value.name}
                                </div>
                              );
                            })
                          }
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
          <div className='products-home-wrapper'>
            {
              Array.isArray(this.state.subcategories) && this.state.subcategories.length ? 
              (
                <div className="categories-page">
                  <h3 className="title">{i18n.t('categories')}</h3>
                  <CategoriesItem categories={this.state.subcategories} />
                  
                </div>
              ) : ( '' )
            }

            <div className={`d-flex m-2 ${this.state.viewType === 'grid' ? 'flex-direction-column' : 'flex-direction-row'}`} style={{margin: "0.7rem"}}>
              <h3 className="title title-padding" id="products-list">{i18n.t('products_navigation')}</h3>
              <div className='d-flex options'>
                <div className='have-found'>
                  <span>
                    {i18n.t('have_found')} { this.state.total } {i18n.t('have_found2')}
                  </span>
                </div>
                <div className={`btn-sort ${this.state.sortMenuOpen ? 'open' : ''}`}>
                  <button
                    type="button"
                    className="btn btn-outline mr-1"
                    onClick={() => this.setState({ ...this.state, sortMenuOpen: !this.state.sortMenuOpen })}
                  >
                    <FontAwesomeIcon icon={faSort} />
                    &nbsp;
                    {i18n.t('sort_by')}
                  </button>
                  <div className="menu">
                    <span className={`${this.state.sort.by + '-' + this.state.sort.type === 'date-desc' ? 'selected' : ''}`} onClick={() => this.sort('date-desc')}>{i18n.t('date_high_to_low')}</span>
                    <span className={`${this.state.sort.by + '-' + this.state.sort.type === 'price-asc' ? 'selected' : ''}`} onClick={() => this.sort('price-asc')}>{i18n.t('price_low_to_high')}</span>
                    <span className={`${this.state.sort.by + '-' + this.state.sort.type === 'price-desc' ? 'selected' : ''}`} onClick={() => this.sort('price-desc')}>{i18n.t('price_high_to_low')}</span>
                  </div>
                </div>  
              </div>
              {/* <div className={`btn-change-view-type ml-1 ${this.state.viewTypeMenuOpen ? 'open' : ''}`}>
                <button
                  type="button"
                  className="btn btn-outline mr-1"
                  onClick={() => this.setState({ ...this.state, viewTypeMenuOpen: !this.state.viewTypeMenuOpen })}
                >
                  {i18n.t('products_view_type')}
                </button>
                <div className="menu">
                  <span className={`${this.state.viewType === 'grid' ? 'selected' : ''}`} onClick={() => this.setState({ ...this.state, viewType: 'grid', viewTypeMenuOpen: false, showFilters: false, })}>{i18n.t('grid')}</span>
                  <span className={`${this.state.viewType === 'list' ? 'selected' : ''}`} onClick={() => this.setState({ ...this.state, viewType: 'list', viewTypeMenuOpen: false, showFilters: false, })}>{i18n.t('list')}</span>
                </div>
              </div> */}
              <div className={`list mt-5`}>
                {
                  this.state.fetchingProducts === false && this.state.products.map((product, i) => {
                    let ProductComponent;
                    if (this.state.viewType === 'grid') {
                      ProductComponent = <ProductItem
                        key={i}
                        data={product}
                      />;
                    
                    } else if (this.state.viewType === 'list') {
                      ProductComponent = <ProductListItem
                        key={i}
                        data={product}
                      />
                    }

                    return ProductComponent;
                  })
                }
                {
                  (this.state.fetchingProducts) && Array.from(Array(12)).map((n, i) => {
                    return (
                      <SkeletonProductItem key={i} type={this.state.viewType}></SkeletonProductItem>
                    );
                  })
                }
                {
                  this.state.fetchingProducts === false && this.state.products.length === 0 && this.state.fetchIsDirty && <div
                    className="no-results-message"
                  > 
                    <FontAwesomeIcon icon={faBoxOpen} />
                    <span>{i18n.t('no_products_found')}</span>
                  </div>
                }
              </div>

            </div>
            {
              !this.state.page
                ? <div className="skeleton-pagination"><div></div><div></div><div></div></div>
                : ''
            }
            <div className={`pagination ${!this.state.page ? 'hidden' : ''}`}>
              <div onClick={() => this.changePage(Math.max(1, this.state.page - 1))}><FontAwesomeIcon icon={faAngleLeft} /></div>
              {
                this.state.page >= 3 && this.state.pagesCount >= 4
                  ? <div onClick={() => this.changePage(1)}>1</div>
                  : ''
              }
              {
                (this.state.page + 1 > this.state.pagesCount && this.state.pagesCount >= 3)
                  ? <div onClick={() => this.changePage(this.state.page - 2)}>{this.state.page - 2}</div>
                  : ''
              }
              {
                this.state.page > 1
                  ? <div onClick={() => this.changePage(this.state.page - 1)}>{this.state.page - 1}</div>
                  : ''
              }
              {
                this.state.pagesCount > 0
                  ? <div className="active">{this.state.page}</div>
                  : ''
              }
              {
                this.state.page + 1 <= this.state.pagesCount
                  ? <div onClick={() => this.changePage(this.state.page + 1)}>{this.state.page + 1}</div>
                  : ''
              }
              {
                this.state.page <= 1 && this.state.pagesCount >= 3
                  ? <div onClick={() => this.changePage(this.state.page + 2)}>{this.state.page + 2}</div>
                  : ''
              }
              {
                this.state.page + 3 <= this.state.pagesCount
                  ? <div onClick={() => this.changePage(this.state.pagesCount)}>{this.state.pagesCount}</div>
                  : ''
              }
              <div onClick={() => this.changePage(Math.min(this.state.pagesCount, this.state.page + 1))}><FontAwesomeIcon icon={faAngleRight} /></div>
            </div>
          </div>
          
        </div>
      </div>
    );
  }
}

export default ProductsHomePage;