import './ProductPrices.css';
import React from 'react';
import i18n from '../i18n';

class ProductPrices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: props.price && props.data ? props.price : props.data[0].price
    }
  }

  componentDidMount() {
    this.unmount = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.unmount()
  }


  render() {
    let cur_price = this.props.price && this.props.data ? this.props.price : this.props.data[0].price
    return (
      <div className="ProductPrices">
        {
         
          <div className="price-pick">
            {
              this.props.data ?
              <ul className="prices-list">
                <li className='price-row header-prices'>
                  <ul>
                    <li>{i18n.t("from_qty_header")} ({i18n.t("pcs")})</li>
                    <li>{i18n.t("price_header")}</li>
                  </ul>
                </li>
              {
                this.props.data.map( (price,i) => {
                  return(
                    <li key={i} className={`price-row ${cur_price == price.price ? 'active' : ''}`}>
                      <ul>
                        <li>{i18n.t("from_qty")} {price.qty} {i18n.t("pcs")}</li>
                        <li>
                          <span className="old-price-li">{price.old_price && price.old_price.toFixed(2) + ' €'}</span>
                          {price.price.toFixed(2)} €
                        </li>
                      </ul>
                    </li>
                  )
                })
              }
              </ul>
              :
              ''
            }
            
          </div>
        }
        {/* <button
          className="btn btn-outline"
          onClick={() => {
            this.setState({
              ...this.state,
              selectedAttributes: {},
            });

            setTimeout(() => {
              this.filterAttributes();
            });
          }}
        >
          {i18n.t('clear')}
        </button> */}
      </div>
    );
  }
}

export default ProductPrices;