import './CategoriesItem.css';
import React from 'react';
import SkeletonCategoryItem from '../category-skeleton-item/SkeletonCategoryItem';
import CategoryItem from '../category-item/CategoryItem';
import i18n from '../i18n';

class CategoriesItem extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }

  render() {
    return (
      <div className="categories-page">
        <div className="list">
          {
            this.props.categories.map((category, i) => {
              return (
                <CategoryItem
                  key={i}
                  data={category}
                />
              );
            })
          }
          {
            this.props.categories.length === 0 && Array.from(Array(12)).map((n, i) => {
              return (
                <SkeletonCategoryItem key={i} />
              );
            })
          }
        </div>
      </div>
    );
  }
}

export default CategoriesItem;