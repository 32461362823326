import './SigninPage.css';
import React from 'react';
import {
  Link,
} from 'react-router-dom';
import i18n from '../i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, } from '@fortawesome/free-solid-svg-icons'
import { } from '@fortawesome/free-regular-svg-icons'
import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons'
import request from '../request'
import Modal from '../modal/Modal';
import history from '../history';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

class SigninPage extends React.Component {
  constructor(props){
    super(props);    

    this.state = {
      vat: "", // sign in
      password: "", // sign in
      isValidEmail: true ,// username email 
      passwordError: "",            
      checked: false, 
      showMessage: false,
      message: "",
      showErrorModal: false,
      modalStatus: false,
      emailForgot:"", // modal form forgot 
      show_pass: false
    };

    this.elementRef = React.createRef();
    
    this.submitSignin = this.submitSignin.bind(this);
    this.handlePassword = this.handlePassword.bind(this); 
    this.handleCheckbox = this.handleCheckbox.bind(this);   
    this.showMessage = this.showMessage.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.submitForgot = this.submitForgot.bind(this);  
  }
  
  componentDidMount() {
    console.log(history)
    document.title = i18n.t("signin_page_title");
    this.unlistenOnLanguageChange = i18n.onLanguageChange(() => {
      this.forceUpdate();
    });

    if(this.props.checkUserLoggedIn()){
      history.push('/')
    }

  }

  componentDidUpdate() {
    document.title = i18n.t("signin_page_title");
  }

  componentWillUnmount() {
    this.unlistenOnLanguageChange();
  }

  handleCheckbox (event) {
    const target = event.target;
    const value = target.checked;    
    const name = target.name;

    this.setState({
      ...this.state,
      checked: value,
    });
  }  

  handleVat(e) {
    this.setState({
      ...this.state,
      vat: e.target.value,
    });
  }

  handlePassword(e) {
    let errorValue;
    if(e.target.value === '') {
       errorValue = 'error';
    }else {
       errorValue = '';
    }

    this.setState({
      ...this.state,
      password: e.target.value,
      passwordError: errorValue,
    });
  }

  vatForgotInput(e) {
    this.setState({
      ...this.state,
      vatForgot: e.target.value
    })
  }

  showMessage(status, message) {   
    this.setState({
      ...this.state,
      message: message,
      showMessage: true
    });
  }

  showMessageModal(status, message) {    
    this.setState({
      ...this.state,
      message: message,
      showErrorModal: true
    });
  } 

  validateEmail(email) {       
    let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;     
    if(email.match(mailformat)) {
      return (true);  //valid
    }else{
      return (false);      
    }  
  }

  // Submit sign in
  submitSignin (e) {
    let vat = this.state.vat;  
    let message; 

    if(this.state.vat !== '' && this.state.password !== '') {
      
      request.post(`api/signin`, { "vat" : this.state.vat, "password" : this.state.password, "remember" : this.state.checked === true ? true : false }).then((response) => {       
        if (response.data.status) {
          this.props.onLogin();
          if(history.last && history.last != '/signin'){
            history.goBack()
          }else{
            history.push('/')
          }
          
        } else {
          this.showMessage(response.data.status, response.data.message);
        }
      });

    } else {
      
      let passwordStatus = this.state.password === '' ? 'error' : '';
      
      if( this.state.vat === '' ||  this.state.password === '' ){
        message = 'Παρακαλώ συμπληρώστε όλα τα πεδία';
      } else if ( this.state.vat === '' ) {
        message = 'Παρακαλώ συμπληρώστε έναν έγκυρο Α.Φ.Μ.';
      } else {
        message = 'Παρακαλώ εισάγετε τον κωδικό σας';
      }


      this.setState({
        ...this.state,
        passwordError: passwordStatus,
        showMessage: true,
        message: message
      })         

    } 
  }

  // submit forgot password
  submitForgot () {

    let vat = this.state.vatForgot;  

    if( vat !== '') {    
      request.post( `api/forgot`, { "vat" : vat }).then( (response) => {
        this.showMessageModal('success', i18n.t('sended_restore_email'));        
      });
    } else {
      if(vat === ''){
        this.showMessageModal('error', 'Συμπληρώστε το πεδίο με το Α.Φ.Μ. σας');
      }else{
        this.showMessageModal('error', 'Το Α.Φ.Μ. που δηλώσατε δεν είναι έγκυρο');
      }      
    }
  }

  render() {
    return (
      <div
        className="signin-page"
        ref={this.elementRef}
      >
        <div className="form">
          <div className="header">
            {i18n.t('signin_on_header')}
          </div>
          <div className="fields">
            <div className="input">
              <span>{i18n.t('tax_registration_number')}</span>
              <input 
                type="text"
                className={`${this.state.emailStatus === false ? 'error' : ''}`}
                value={this.state.email}
                onChange={ (e) => {this.handleVat(e) }}
                onKeyDown={(e) => e.key === 'Enter' && this.submitSignin()}
                autocomplete="UserName"
              />
            </div>
            <div className="input" style={{position: 'relative'}}>
              <div className="d-flex flex-direction-row justify-content-between align-items-start w-100">
                <span>{i18n.t('password')}</span>
                <p onClick={ () => this.setState({...this.state, modalStatus: true})} className="fs-sm forgot">{i18n.t('forgot_password')}</p>
              </div>
              <input 
                type={this.state.show_pass ? 'text' : 'password'}
                className={`${this.state.passwordError == 'error' ? 'error' : ''}`}
                value={this.state.password}
                onChange={ (e) => { this.handlePassword(e) }}
                onKeyDown={(e) => e.key === 'Enter' && this.submitSignin()}
              />
              <FontAwesomeIcon style={{position: 'absolute', right: '10px', top: '55%', cursor: 'pointer'}} icon={this.state.show_pass ? faEyeSlash : faEye} size="sm" 
                onClick={() => this.setState({
                  ...this.state,
                  show_pass: !this.state.show_pass
                })}
              />
            </div>
            

            <div className={`alert alert-danger login-response ${this.state.showMessage === false ? 'hidden' : ''} `}>
              {this.state.message}
            </div>

          </div>
          <div className="controls">
            <button
              onClick={this.submitSignin}
              type="button"
              className="btn-signin"
            >
              {i18n.t('signin_button')}
            </button>
          </div>
          <div className="text-center my-1 fs-md">
            {i18n.t('dont_have_an_account')}
            &nbsp;
            <Link to="/signup">{i18n.t('signup')}</Link>
          </div>
        </div>

        <Modal
          modalTitle={i18n.t("restore_password_title")}
          showModal = {this.state.modalStatus}
          onClose = { () => this.setState({...this.state, modalStatus: false})}
        >
          <div className="container" >
            <div className={`error login-response mb-2 ${this.state.showErrorModal === false ? 'hidden' : ''}`} style={{background: '#caf5af', padding: '10px'}}>
              {this.state.message}
            </div>
            <div className={`w-100 ${this.state.showErrorModal !== false ? 'hidden' : ''}`} style={{background: '#fff'}}>
              <span style={{textAlign: 'center', display: 'block'}}>{i18n.t("forgot_password_prompt")}</span>
              <div className="form">
                <div className="fields">
                  <div className="input">
                    <span>{i18n.t("tax_registration_number")}</span>
                    <input
                      type="text"
                      className="form-control mb-2"
                      onChange={ (e) => { this.vatForgotInput(e) } }
                    />
                  </div>

                  <div className="mb-20">
                    <input
                    style={{border: 'none', color: '#fff'}}
                    type="submit"
                    className="btn btn-outline btn-subm" 
                    onClick={() => {this.submitForgot() }} 
                    value={i18n.t("submit_forgot")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default SigninPage;